.multi-step-checkout-process-step {
  margin-top: 20px;
  margin-bottom: 3rem;
  
  @include breakpoint(small down) {
    padding-right: 0rem;
  }
}
.multi-step-checkout-process {
  .accordion-title {
    color: darken($dark-gray,30%);
    font-size: 1.25rem;
    transition: color 0.5s ease;
    border: none;
    font-family: 'Droid serif', serif;
    
    &:hover,
    &:active,
    &:focus {
      color: darken($dark-gray,40%);
      transition: color 0.5s ease;
      background: none;
    }
  }

  :last-child:not(.is-active) > .accordion-title {
    border: none;
  }

  .accordion-item {
    border-bottom: 1px solid $light-gray;
    padding-right: 0.8rem;
    
    &:first-child() {
      border-top: 1px solid $light-gray;
    }
    
    &.is-active {
      border: 1px solid $light-gray;
      border-top: 3px solid $primary-color;
      border-radius: 3px;
      box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1); 
    }
    
    .accordion-title {
      font-weight: 400;
    }
    
    .accordion-title::before {
      right: 0.5rem;
    }
  }

  .accordion-content {
    border: none;
    padding-left: 35px;
  }
  :last-child > .accordion-content:last-child {
    border-bottom: none;
  }
}

.multi-step-checkout-step-title-subheader {
  color: lighten($dark-gray,10%);
  font-size: 0.9rem;
  display: inline-block;
  margin-left: 10px;
  font-family: 'Open sans', serif;
}
  
.multi-step-checkout-step-subheader {
  color: darken($dark-gray,30%);
  font-size: 0.9rem;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
}

.multi-step-checkout-step-subdesc {
  color: lighten($dark-gray,0%);
  font-size: 0.9rem;
}

.multi-step-checkout-shipping-option {
  margin-bottom: 10px;
}

.multi-step-checkout-step-section {
  border-bottom: 1px dashed $light-gray;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.multi-step-checkout-shipping-option-selection {
  position: absolute;
  margin-top: 0.2rem;
}

.multi-step-checkout-shipping-option-title {
  display: block;
  font-size: 0.9rem;
  line-height: 1.4rem;
  margin-left: 1.6rem;
  margin-bottom: 0.8rem;
}

.multi-step-checkout-shipping-option-desc {
  font-size: 0.9rem;
  color: $dark-gray;
  margin-left: 25px;
  margin-top: -10px;
}

.multi-step-checkout-shipping-cost {
  text-align: right;
}

.multi-step-checkout-form-divider {
  border-bottom: 1px dashed $light-gray;
}

.multi-step-checkout-form {
  input {
    background: lighten($light-gray,7%);
  }
}

.multi-step-checkout-enews-sign-up {
  label {
    position: relative;
    margin-left: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.multi-step-checkout-enews-sign-up-checkbox {
  position: absolute;
  margin-top: 0.4rem;
}
  

.multi-step-checkout-credit-card-info {
  label {
    span {
      color: lighten($dark-gray,10%);
    }
  }
}

.multi-step-checkout-payment-icons {
  list-style: none;
  margin-left: 0;
  margin-bottom: 1rem;
  
  li {
    display: inline;
  }
}

.multi-step-checkout-billing-address {
  .multi-step-checkout-billing-address-list {
    list-style: none;
    margin-left: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0.8rem;
    color: $dark-gray;
    font-size: 0.9rem;
  }
  
  .multi-step-checkout-billing-address-checkbox {
    position: absolute;
    margin-top: 0.4rem;
  }
  
  label {
    margin-left: 1.5rem;
  }
}

.multi-step-checkout-create-account {
  margin-top: 2rem;
  
  @include breakpoint(small down) {
    margin-top: 0.5rem;
  }
  
  .create-account-desc {
    font-size: 0.9rem;
    color: $dark-gray;
  }
}

.multi-step-checkout-logo {
  width: 118px;
  height: 38px;
}

.multi-step-checkout-arrow {
  height: 13px;
  width: 13px;
}

