.product-details {
  background-color: $white;
  padding: 2rem;
}

.product-option-selection{
  
  input[type=radio] {
    display: none; 
    margin: 10px;
  }
  
  input[type=radio] + label {
    display: inline-block;
    margin: -2px;
    border-color: #ddd;
    margin-right: 0.3rem;
    margin-top: 2px;
    margin-bottom: 10px;
    padding: 0.4rem;
  }
  
  input[type=radio]:checked + label {     
    background: lighten($primary-color, 43%);
    border: 1px solid $primary-color;
    border-radius: 3px;
    color: lighten($black, 20%);
    font-weight: 600;
    padding: 0.3rem;
  }
}

.product-details-add-to-cart {
  margin-top: 1rem;
  border-radius: 5px;
  background-color: $white;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.2);
  border: solid 1px $light-gray;
  margin-bottom: 25px;
  padding: 22px;
  
  .in-stock {
    color: #1eac60;
    font-weight: 700;
  }
  
  .dim-text {
    color: $dark-gray;
  }
  
  .price {
    float: right;
    font-weight: 600;
    font-size: 24px;
    color: darken($dark-gray,25%);
  }
  
  .qty {
    width: 60px;
    display: inline-block;
    text-align: center;
    margin-left: 15px;
    margin-bottom: 0;
  }
  
  .button {
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 600;
  }
}
